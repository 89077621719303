$(document).ready(() => {
  // Single Ken Burns Image
  $('.inactiveUntilOnLoad').removeClass('inactiveUntilOnLoad');

  // Offset Fixed Navigation
  $('.nav-link, .mobile-links a, .btn-prime').click(e => {
    const linkTarget = $(e.currentTarget).attr('href');

    if (linkTarget === '#' || linkTarget.charAt(0) !== '#') {
      return;
    }
    e.preventDefault();

    let topOffset = $(linkTarget).offset().top;
    let navContainer = '.fixed-top';
    if ($('.mobile-nav').is(':visible')) {
      if ($('#burger').is(':checked')) {
        navContainer = '.mobile-menu input~nav';
        $('#burger').trigger('click');
      }
    }

    topOffset -= $(navContainer).height();

    $('html, body').animate({
      scrollTop: topOffset
    }, 900);
  });

  $('.bundle-slider').slick({
    // autoplay: true,
    // autoplaySpeed: 2000,
    arrows: false,
    infinite: true,
    dots: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1
  });

  $('.product-prev').click(function() {
    $('.bundle-slider').slick('slickPrev');
  });

  $('.product-next').click(function() {
    $('.bundle-slider').slick('slickNext');
  });

//   Scratch Card
// $('#card').wScratchPad({
//     size: 75, // The size of the brush/scratch.
//     bg: 'dist/img/scratch-after.png', // Background (image path or hex color).
//     fg: 'dist/img/scratch-before.png', // Foreground (image path or hex color).
//     cursor: 'crosshair',
//   }); // Set cursor.
});

